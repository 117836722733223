import React from 'react'
import { graphql } from 'gatsby'
import PageHelmet from '../components/PageHelmet'

const PageAboutUs = ({ data: { humans, title, about } }) => {
  const styles = `
    h1 {
      border-bottom: solid 1px #dfdfdf;
      padding-bottom: .5rem;
    }
    .teamList {
      display: flex;
      flex-wrap: wrap;
      marign-left: -15px;
      margin-right: -15px;
    }
    .personalCard {
      text-align: center;
      padding: 10px;
    }
    .personalCard img {
      border-radius: 50%;
      border: solid 2px #fff;
      box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.125);
      height: 140px;
    }
    .personalCard p {
      border-top: solid 1px rgba(0, 0, 0, 0.03);
      font-size: 0.8rem;
      max-width: 200px;
      margin: 0 auto;
      padding: 20px 5px 5px;
      position: relative;
    }

    @media (min-width: 0px) {
      .personalCard {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .press,
      .awards {
        flex: 0 0 100%;
        max-width: 100%;
      }
      .pressCoverageIcon {
        flex: 0 0 100%;
        max-width: 100%;
      }
    }
    @media (min-width: 576px) {
      .personalCard {
        flex: 0 0 50%;
        max-width: 50%;
      }
      .pressCoverageIcon {
        flex: 0 0 33.33%;
        max-width: 33.33%;
      }
    }
    @media (min-width: 768px) {
      .personalCard {
        flex: 0 0 30%;
        max-width: 30%;
      }
      .press,
      .awards {
        flex: 0 0 50%;
        max-width: 50%;
      }
    }
    @media (min-width: 992px) {
      .personalCard {
        flex: 0 0 25%;
        max-width: 25%;
      }
    }
  `

  return (
    <>
      <PageHelmet title="About WikiJob" />
      <style dangerouslySetInnerHTML={{ __html: styles }} />
      <h1>{title}</h1>
      <div
        dangerouslySetInnerHTML={{
          __html: about.pageContentNode.childMarkdownRemark.html,
        }}
      />
      {humans && (
        <div className="teamList">
          {humans.edges.map(
            ({
              node: {
                id,
                name,
                role,
                picture: { url: pictureUrl },
              },
            }) => (
              <div
                {...{
                  className: 'personalCard',
                  key: id,
                }}
              >
                <img
                  {...{
                    src: `${pictureUrl}?w=136`,
                    title: name,
                    alt: name,
                  }}
                />
                <h4>
                  {name} <small>{role}</small>
                </h4>
              </div>
            ),
          )}
        </div>
      )}
    </>
  )
}

export default PageAboutUs

export const query = graphql`
  {
    humans: allDatoCmsTeam {
      edges {
        node {
          id
          name
          role
          description
          picture {
            url
          }
        }
      }
    }
    about: datoCmsAbout {
      title
      pageContentNode {
        childMarkdownRemark {
          html
        }
      }
      awardNode {
        childMarkdownRemark {
          html
        }
      }
      pressNode {
        childMarkdownRemark {
          html
        }
      }
    }
  }
`
